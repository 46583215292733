import { InputHTMLAttributes } from "react";

import { SocialIcon } from "./SocialIcon";
import { Checkbox } from "../../../components";
import { cx } from "../../../utils";

interface ISocialRestrictionItem extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  icon: string;
  className?: string;
  label: string;
  lastChild?: boolean;
  isChecked: boolean;
}

export const SocialRestrictionItem: React.FC<ISocialRestrictionItem> = ({
  icon,
  id,
  className,
  lastChild,
  label,
  isChecked,
  ...props
}) => {
  return (
    <Checkbox
      labelClassName={cx([
        "w-full text-sm inline-flex items-center py-3 px-4 border-primary-stroke cursor-pointer transition hover:bg-gray-50",
        !lastChild && "border-b",
        isChecked && "text-primary-mainText",
        !isChecked && "text-primary-secText",
      ])}
      labelColor={cx([
        isChecked && "text-primary-mainText",
        !isChecked && "text-primary-secText",
      ])}
      label={
        <div className="flex items-center back">
          <SocialIcon iconName={icon} />
          {label}
        </div>
      }
      id={id}
      checked={false}
      {...props}
    />
  );
};
