import { useEffect } from "react";

import { Secrets } from "../../components";
import { APPS_PATH } from "../../constants";
import { SEO } from "../../utils";

export const ApplicationSecrets = () => {
  useEffect(() => {
    SEO({
      title: "Porta - Application Secrets",
    });
  }, []);

  return (
    <Secrets
      endpoint="/Clients"
      mainTitle="Application Secrets"
      backTo={APPS_PATH}
    />
  );
};
