export const reactSelectStyle: any = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
    fontSize: 14
  }),
  control: (base: any) => ({
    ...base,
    minHeight: 38,
    borderColor: 'rgb(234, 234, 234)'
  }),
  option: (base: any) => ({
    ...base,
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#F5F5F5' }
  })
}
