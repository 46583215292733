//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'
import { cx } from '../../utils'

interface ITipInfo {
  className?: string
  message: string
}

const TipInfo: React.FC<ITipInfo> = ({ className, message }) => {
  return (
    <div
      className={cx([
        'relative py-2.5 pl-14 text-primary-secText bg-white rounded-lg overflow-hidden flex items-center bg-gray-100',
        className
      ])}
    >
      <div className="absolute left-0 top-0 bg-primary-stroke w-10 h-full flex items-center justify-center">
        <div className="w-5 h-5">
          <Icon name="Info" size={22} />
        </div>
      </div>
      <span className="text-sm">{message}</span>
    </div>
  )
}

export default TipInfo
