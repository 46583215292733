import { FC, useEffect, useState } from 'react'

import { useLocation, useParams } from 'react-router-dom'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'

import { FormProvider, useForm } from 'react-hook-form'
import {
  BackButton,
  Card,
  CardSpace,
  CardStyle,
  FormFooter,
  Loader,
  Modal,
  StaticTabs,
  TopDetails
} from '../../components'
import { MainContainer } from '../../components/layout/MainContainer'
import { APPS_PATH } from '../../constants'
import { useApplicationContext } from '../../context/ApplicationContext'
import { useFetch, useToggle } from '../../hooks'
import { IApplication, IRestriction, ISecret } from '../../interfaces'
import { SEO } from '../../utils'
import {
  Authentication,
  Basics,
  Consent,
  DeviceFlow,
  Token
} from './components'
import { ILocation } from './interface'

export const Application: FC = () => {
  const { state } = useLocation() as ILocation
  const { toggle, visible } = useToggle()

  // TODO: place all of them in one state
  const [scopes, setScopes] = useState<string[]>([])
  const [grantTypes, setGrantTypes] = useState<string[]>([])
  const [redirectUris, setRedirectUris] = useState<string[]>([])
  const [logoutRedirects, setLogoutRedirects] = useState<string[]>([])
  const [tokenAlgorithms, setTokenAlgorithms] = useState<string[]>([])
  const [allowCors, setAllowCors] = useState<string[]>([])
  const [restrictions, setRestrictions] = useState<IRestriction[]>([])

  // TODO: should remove this context
  const {
    appData,
    setAppData,
    setAppId,
    loading: appDataLoading
  } = useApplicationContext()

  const {
    id: clientMainId,
    logoUri,
    allowedScopes,
    clientId,
    clientName,
    clientType,
    clientSecret,
    identityProviderRestrictions: irestrictions,
    allowedIdentityTokenSigningAlgorithms,
    redirectUris: iredirectUris,
    postLogoutRedirectUris,
    allowedCorsOrigins,
    allowedGrantTypes,
    identityProvidersAvailable
  } = !!appData && appData

  let { id } = useParams()

  useEffect(() => {
    SEO({
      title: 'Porta - Application'
    })
  }, [])

  useEffect(() => {
    setAppId(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const { apiCall: updateApplication, loading } = useFetch('put')

  const methods = useForm<IApplication>()
  const { handleSubmit, reset, formState } = methods

  useEffect(() => {
    let socialRestrictions: IRestriction[] = []

    identityProvidersAvailable?.forEach((item: any) => {
      socialRestrictions.push({
        label: item,
        icon: item.toLowerCase(),
        id: item.toLowerCase(),
        checked: false
      })
    })

    socialRestrictions?.forEach((social) => {
      if (irestrictions?.includes(social.label)) social.checked = true
      else social.checked = false
    })

    setRestrictions(socialRestrictions)
  }, [irestrictions, identityProvidersAvailable])

  useEffect(() => {
    !!appData && reset(appData)
    !!allowedScopes && setScopes(allowedScopes)
    !!allowedIdentityTokenSigningAlgorithms &&
      setTokenAlgorithms(allowedIdentityTokenSigningAlgorithms)
    !!iredirectUris && setRedirectUris(iredirectUris)
    !!allowedGrantTypes && setGrantTypes(allowedGrantTypes)
    !!postLogoutRedirectUris && setLogoutRedirects(postLogoutRedirectUris)
    !!allowedCorsOrigins && setAllowCors(allowedCorsOrigins)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData])

  const { ...dirtyFields } = !!formState.dirtyFields && formState.dirtyFields

  const tabOptions = [
    {
      name: 'Basics',
      isActive: false,
      component: (
        <Basics
          scopes={scopes}
          setScopes={setScopes}
          redirectUris={redirectUris}
          setRedirectUris={setRedirectUris}
          grantTypes={grantTypes}
          setGrantTypes={setGrantTypes}
          client={{ clientId: clientId, clientName, id: clientMainId }}
        />
      ),
      index: 0,
      dotVisible:
        dirtyFields?.clientName === true ||
        dirtyFields.allowAccessTokensViaBrowser === true ||
        dirtyFields.allowOfflineAccess === true ||
        dirtyFields.allowPlainTextPkce === true ||
        dirtyFields.description === true ||
        dirtyFields.requirePkce === true ||
        dirtyFields.requireRequestObject === true ||
        allowedScopes?.length !== scopes?.length ||
        redirectUris?.length !== iredirectUris?.length ||
        grantTypes?.length !== allowedGrantTypes?.length
    },
    {
      name: 'Authentication/Logout',
      isActive: false,
      component: (
        <Authentication
          logoutRedirects={logoutRedirects}
          setLogoutRedirects={setLogoutRedirects}
          restrictions={restrictions}
          setRestrictions={setRestrictions}
        />
      ),
      index: 1,
      dotVisible:
        dirtyFields.backChannelLogoutSessionRequired === true ||
        dirtyFields.backChannelLogoutUri === true ||
        dirtyFields.enableLocalLogin === true ||
        dirtyFields.frontChannelLogoutSessionRequired === true ||
        dirtyFields.frontChannelLogoutUri === true ||
        dirtyFields.userSsoLifetime === true ||
        postLogoutRedirectUris?.length !== logoutRedirects?.length ||
        restrictions?.length !== identityProvidersAvailable?.length
    },
    {
      name: 'Token',
      isActive: false,
      component: (
        <Token
          tokenAlgorithms={tokenAlgorithms}
          setTokenAlgorithms={setTokenAlgorithms}
          allowCors={allowCors}
          setAllowCors={setAllowCors}
          client={{ clientId: clientId, clientName, id: clientMainId }}
        />
      ),
      index: 2,
      dotVisible:
        dirtyFields.absoluteRefreshTokenLifetime === true ||
        dirtyFields.accessTokenLifetime === true ||
        dirtyFields.accessTokenType === true ||
        dirtyFields.alwaysIncludeUserClaimsInIdToken === true ||
        dirtyFields.alwaysSendClientClaims === true ||
        dirtyFields.authorizationCodeLifetime === true ||
        dirtyFields.clientClaimsPrefix === true ||
        dirtyFields.identityTokenLifetime === true ||
        dirtyFields.pairWiseSubjectSalt === true ||
        dirtyFields.refreshTokenExpiration === true ||
        dirtyFields.refreshTokenUsage === true ||
        dirtyFields.slidingRefreshTokenLifetime === true ||
        tokenAlgorithms?.length !==
          allowedIdentityTokenSigningAlgorithms?.length ||
        allowCors?.length !== allowedCorsOrigins?.length
    },
    {
      name: 'Consent Screen',
      isActive: false,
      component: <Consent />,
      index: 3,
      dotVisible:
        dirtyFields.requireConsent === true ||
        dirtyFields.allowRememberConsent === true ||
        dirtyFields.clientUri === true ||
        dirtyFields.logoUri === true
    },
    {
      name: 'Device Flow',
      isActive: false,
      component: <DeviceFlow />,
      index: 4,
      dotVisible:
        dirtyFields.deviceCodeLifetime === true ||
        dirtyFields.userCodeType === true
    }
  ]

  const onApplicationUpdate = (data: (IApplication & ISecret) | any) => {
    let checkedRestrictions = [] as string[]
    restrictions.filter(
      (item) => item.checked === true && checkedRestrictions.push(item.label)
    )

    const appDataObj = {
      ...data,
      clientName: data?.clientName?.trim(),
      clientType: clientType || 'Empty',
      allowedScopes: scopes,
      allowedGrantTypes: grantTypes,
      allowedIdentityTokenSigningAlgorithms: tokenAlgorithms,
      redirectUris,
      postLogoutRedirectUris: logoutRedirects,
      allowedCorsOrigins: allowCors,
      clientId,
      clientSecret,

      requireConsent: Boolean(data?.requireConsent),
      allowRememberConsent: Boolean(data?.allowRememberConsent),

      identityProviderRestrictions: checkedRestrictions,
      refreshTokenUsage: parseInt(data.refreshTokenUsage),
      refreshTokenExpiration: parseInt(data.refreshTokenExpiration),
      accessTokenType: parseInt(data.accessTokenType),
      userSsoLifetime: parseInt(data.userSsoLifetime) || null
    }

    if (Object.keys(formState.errors).length > 0 || visible) {
      updateApplication(
        `/Clients`,
        appDataObj,
        () => {
          showToast('success', 'Application successfully updated!')
          toggle()
          setAppData(appDataObj)
        },
        (err) => {
          toggle()
          const response =
            !!err?.response?.data?.errors && err?.response?.data?.errors

          !!response?.ClientName && showToast('error', response?.ClientName[0])

          !!response?.DeviceCodeLifetime &&
            showToast('error', response?.DeviceCodeLifetime[0])

          !!response?.AccessTokenLifetime &&
            showToast('error', response?.AccessTokenLifetime[0])

          !!response?.IdentityTokenLifetime &&
            showToast('error', response?.IdentityTokenLifetime[0])

          !!response?.AbsoluteRefreshTokenLifetime &&
            showToast('error', response?.AbsoluteRefreshTokenLifetime[0])

          !!response?.SlidingRefreshTokenLifetime &&
            showToast('error', response?.SlidingRefreshTokenLifetime[0])

          !!response?.AuthorizationCodeLifetime &&
            showToast('error', response?.AuthorizationCodeLifetime[0])
        }
      )
    } else {
      toggle()
    }
  }

  if (appDataLoading) {
    return <Loader />
  }

  return (
    <MainContainer className="flex-grow">
      <div>
        <BackButton
          to={APPS_PATH}
          className="mb-4"
          label="Back to Applications"
        >
          <></>
        </BackButton>

        <TopDetails
          imgUrl={logoUri || ''}
          title={clientName}
          message={clientType || ''}
          textToCopy={clientId}
          copyTextLabel="Client ID"
          index={state?.index}
          protocolType="oidc"
        />
      </div>

      <FormProvider {...methods}>
        <form className="flex flex-col flex-grow">
          <StaticTabs tabs={tabOptions} wrapperClassName="mb-auto">
            {tabOptions.map((tab) => (
              <div tabIndex={tab?.index} key={tab?.index}>
                <Card
                  cardStyle={CardStyle.bordered}
                  cardSpace={CardSpace.xl}
                  className="mt-8"
                >
                  {tab.component}
                </Card>
              </div>
            ))}
          </StaticTabs>

          <FormFooter
            onSubmit={handleSubmit(onApplicationUpdate)}
            // loading={loading}
            // disabled={!formState.isDirty}
            submitBtnType="button"
            className="w-full"
            pb="pb-0"
          />
        </form>
      </FormProvider>

      {visible && (
        <Modal
          visible={visible}
          hide={toggle}
          title="Are you sure you want to save these changes?"
          onConfirmClick={handleSubmit(onApplicationUpdate)}
          confirmBtnText="Save"
          withFooter
          blockOutsideClick
          loading={loading}
        >
          <p className="text-sm text-gray-700">
            Please note that this can have implications in your implementation
            and may cause breaking changes.
          </p>
        </Modal>
      )}
    </MainContainer>
  )
}
