import { FC, useEffect, useState } from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation } from 'react-router'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'
import { useParams } from 'react-router-dom'

import { IProperty } from '../../interfaces'
import { BackButton, Container, SectionTitle } from '../../components'
import { propertiesIState } from '../../utils'
import { NewProperyForm, PropertiesTable } from './components'
import { useCurrentPage, useFetch } from '../../hooks'

interface IProperties {
  endpoint: string
  mainTitle: string
  backTo: string
}

export const Properties: FC<IProperties> = ({
  endpoint,
  mainTitle,
  backTo
}) => {
  let { id: paramId } = useParams()
  const { state } = useLocation() as any
  const { currentPage, handlePageChange } = useCurrentPage()

  const [response, setResponse] = useState<any>()
  const PER_PAGE = 10

  const { apiCall: getApplicationProps, loading } = useFetch('get')
  const { apiCall: addApplicationProp, loading: addAppPropLoading } =
    useFetch('post')

  const { properties, totalCount } = !!response && response

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    if (!!paramId)
      getApplicationProps(
        `${endpoint}/${paramId}/Properties?page=${currentPage}&pageSize=${PER_PAGE}`,
        {},
        (response) => {
          setResponse(response)
        }
      )
  }, [paramId, currentPage]) // eslint-disable-line react-hooks/exhaustive-deps

  const addNewProperty: SubmitHandler<any> = (data: IProperty) => {
    const { value, key } = data

    const propertyObject = {
      ...propertiesIState,
      value: value || propertiesIState.value,
      key: key || propertiesIState.key
    }

    addApplicationProp(
      `${endpoint}/${paramId}/Properties`,
      propertyObject,
      (response) => {
        setResponse({
          ...response,
          totalCount: totalCount + 1,
          properties: [...properties, response]
        })

        reset()
        showToast('success', 'New property added successfully!')
      }
    )
  }

  return (
    <>
      <Container>
        <BackButton
          to={
            backTo === '/applications'
              ? `${backTo}/${paramId}/settings`
              : `${backTo}/${paramId}`
          }
          label="Back"
        >
          <></>
        </BackButton>
        <SectionTitle>
          {mainTitle} ({state?.name})
        </SectionTitle>

        <NewProperyForm
          onSubmit={handleSubmit(addNewProperty)}
          register={register}
          errors={errors}
          className="mb-6"
          loading={addAppPropLoading}
        />

        <PropertiesTable
          perPage={PER_PAGE}
          response={response}
          setResponse={setResponse}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          endpoint={endpoint}
          loading={loading}
        />
      </Container>
    </>
  )
}
