import { FC } from "react";
import { useFormContext } from "react-hook-form";

import { TimeControllFrame } from ".";
import { FormControl, Label, Input, InputSize } from "../../../components";
import { IApplication } from "../../../interfaces";
import { inputClasses } from "../../../utils";

export const DeviceFlow: FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IApplication>();

  return (
    <>
      <FormControl description="Specifies the type of user code to use for the client. Otherwise falls back to default.">
        <Label text="User Code Type" htmlFor="user-code-type" />
        <Input
          id="user-code-type"
          {...register("userCodeType")}
          inputSize={InputSize.sm}
          className={inputClasses}
        />
      </FormControl>

      <FormControl description="Lifetime of device code in seconds (defaults to 300 seconds / 5 minutes)">
        <Label required text="Device Code Lifetime" />
        <TimeControllFrame text="Seconds">
          <Input
            {...register("deviceCodeLifetime", {
              required: "Device Code Lifetime is required",
              valueAsNumber: true,
            })}
            min="0"
            error={
              (!!errors.deviceCodeLifetime &&
                errors.deviceCodeLifetime.message) ||
              ""
            }
            className={inputClasses}
            inputSize={InputSize.sm}
            type="number"
          />
        </TimeControllFrame>
      </FormControl>
    </>
  );
};
