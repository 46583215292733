import { Dispatch, FC, SetStateAction, useEffect } from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { NavLink } from 'react-router-dom'

import { TimeControllFrame } from '.'
import {
  BtnSize,
  BtnStyle,
  Button,
  Checkbox,
  DividedSection,
  FormControl,
  Input,
  InputSize,
  Label,
  Select
} from '../../../components'
import { MultiTagsCard } from '../../../components/ui/MultiTagsCard'
import { APPS_PATH } from '../../../constants'
import { IApplication } from '../../../interfaces'
import { inputClasses, primaryBtnClasses } from '../../../utils'

interface IAuthentication {
  tokenAlgorithms: string[]
  setTokenAlgorithms: Dispatch<SetStateAction<string[]>>
  allowCors: string[]
  setAllowCors: Dispatch<SetStateAction<string[]>>
  client: {
    clientId: string
    clientName: string
    id: string
  }
}

export const Token: FC<IAuthentication> = (props) => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext<IApplication>()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <FormControl
        description="Lifetime of identity token in seconds (defaults to 300 seconds / 5
          minutes)"
      >
        <Label required text="ID Token Expiration" htmlFor="i-token-lifetime" />
        <TimeControllFrame text="Seconds">
          <Input
            id="i-token-lifetime"
            {...register('identityTokenLifetime', {
              required: 'ID Token Expiration is required',
              valueAsNumber: true
            })}
            error={
              (!!errors.identityTokenLifetime &&
                errors.identityTokenLifetime.message) ||
              ''
            }
            inputSize={InputSize.sm}
            type="number"
            className={inputClasses}
          />
        </TimeControllFrame>
      </FormControl>

      <FormControl description="List of allowed signing algorithms for identity token. If empty, will use the server default signing algorithm.">
        <Label text="Allowed Identity Token Signing Algorithms" />
        <MultiTagsCard
          tags={props.tokenAlgorithms}
          setTags={props.setTokenAlgorithms}
          suggestedEndpoint="/Clients/SearchSigningAlgorithms?limit=0"
        />
      </FormControl>

      <FormControl description="Lifetime of access token in seconds (defaults to 3600 seconds / 1 hour)">
        <Label
          text="Access Token Lifetime"
          htmlFor="access-token-lifetime"
          required
        />
        <TimeControllFrame text="Seconds">
          <Input
            id="access-token-lifetime"
            {...register('accessTokenLifetime', {
              required: 'Access Token Lifetime is required',
              valueAsNumber: true
            })}
            error={
              (!!errors.accessTokenLifetime &&
                errors.accessTokenLifetime.message) ||
              ''
            }
            inputSize={InputSize.sm}
            type="number"
            className={inputClasses}
          />
        </TimeControllFrame>
      </FormControl>

      <FormControl
        className="z-10"
        description="Specifies whether the access token is a reference token or a self
          contained JWT Token (defaults to JWT)."
      >
        <Label text="Access Token Type" />
        <Controller
          control={control}
          name="accessTokenType"
          render={({ field: { ref, onChange, value } }: any) => (
            <Select
              selectRef={ref}
              placeholder=""
              className={inputClasses}
              options={[
                { label: 'JWT', value: '0' },
                { label: 'Reference', value: '1' }
              ]}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </FormControl>

      <FormControl description="Lifetime of authorization code in seconds (defaults to 300 seconds / 5 minutes)">
        <Label
          text="Authorization Code Lifetime"
          htmlFor="auth-code-lifetime"
          required
        />
        <TimeControllFrame text="Seconds">
          <Input
            id="auth-code-lifetime"
            {...register('authorizationCodeLifetime', {
              required: 'Authorization Code Lifetime is required',
              valueAsNumber: true
            })}
            error={
              (!!errors.authorizationCodeLifetime &&
                errors.authorizationCodeLifetime.message) ||
              ''
            }
            inputSize={InputSize.sm}
            type="number"
            className={inputClasses}
          />
        </TimeControllFrame>
      </FormControl>

      <FormControl description="Maximum lifetime of a refresh token in seconds. Defaults to 2592000 seconds / 30 days">
        <Label required text="Absolute Refresh Token Lifetime" />
        <TimeControllFrame text="Seconds">
          <Input
            {...register('absoluteRefreshTokenLifetime', {
              required: 'Absolute Refresh Token Lifetime is required',
              valueAsNumber: true
            })}
            error={
              (!!errors.absoluteRefreshTokenLifetime &&
                errors.absoluteRefreshTokenLifetime.message) ||
              ''
            }
            inputSize={InputSize.sm}
            type="number"
            className={inputClasses}
          />
        </TimeControllFrame>
      </FormControl>

      <FormControl description="Sliding lifetime of a refresh token in seconds. Default to 1296000 seconds / 15 days">
        <Label required text="Sliding Refresh Token Lifetime" />
        <TimeControllFrame text="Seconds">
          <Input
            {...register('slidingRefreshTokenLifetime', {
              required: 'Sliding Refresh Token Lifetime is required',
              valueAsNumber: true
            })}
            inputSize={InputSize.sm}
            type="number"
            className={inputClasses}
            error={
              (!!errors.slidingRefreshTokenLifetime &&
                errors.slidingRefreshTokenLifetime.message) ||
              ''
            }
          />
        </TimeControllFrame>
      </FormControl>

      <FormControl
        className="z-10"
        description="
                ReUse the refresh token handle will stay the same when refreshing tokens OneTime the refresh token handle will be updated when refreshing tokens"
      >
        <Label text="Refresh Token Usage" />
        <Controller
          control={control}
          name="refreshTokenUsage"
          render={({ field: { ref, onChange, value } }: any) => (
            <Select
              selectRef={ref}
              placeholder=""
              className={inputClasses}
              options={[
                { label: 'Reuse', value: '0' },
                { label: 'One Time Only', value: '1' }
              ]}
              onChange={onChange}
              value={value || ''}
            />
          )}
        />
      </FormControl>

      <FormControl
        className="z-10"
        description="
                Absolute refresh token will expire on a fixed point in time (specified by the AbsoluteRefreshTokenLifetime) Sliding when refreshing the token, the lifetime of the refresh token will be renewed (by the amount specified in SlidingRefreshTokenLifetime). The lifetime will not exceed AbsoluteRefreshTokenLifetime."
      >
        <Label text="Refresh Token Expiratione" />
        <Controller
          control={control}
          name="refreshTokenExpiration"
          render={({ field: { ref, onChange, value } }: any) => (
            <Select
              selectRef={ref}
              placeholder=""
              className={inputClasses}
              options={[
                { label: 'Sliding', value: '0' },
                { label: 'Absolute', value: '1' }
              ]}
              onChange={onChange}
              value={value || ''}
            />
          )}
        />
      </FormControl>

      <FormControl
        description={
          <>
            Allowed Origins are URLs that will be allowed to make requests from
            JavaScript Clients to Porta API (typically used with CORS). By
            default, none of callback URLs will be allowed. This field allows
            you to enter origins that you want to allow.
          </>
        }
      >
        <Label text="Allowed CORS Origins" />
        <MultiTagsCard
          tags={props.allowCors}
          setTags={props.setAllowCors}
          suggestedEndpoint=""
          noSuggestedTags
          hasInput
        />
      </FormControl>

      <DividedSection>Properties</DividedSection>
      <FormControl description="Gets or sets a value indicating whether the access token (and its claims) should be updated on a refresh token request.">
        <Controller
          control={control}
          name="updateAccessTokenClaimsOnRefresh"
          render={({ field: { onChange, value, ref } }: any) => (
            <Checkbox
              label="Update Access Token Claims on Refresh"
              id="updateAccessTokenClaim"
              checked={value || false}
              inputRef={ref}
              onChange={onChange}
              wrapperClassName="mr-2"
            />
          )}
        />
      </FormControl>

      <FormControl description="Specifies whether JWT access tokens should have an embedded unique ID (via the jti claim).">
        <Controller
          control={control}
          name="includeJwtId"
          render={({ field: { onChange, value, ref } }: any) => (
            <Checkbox
              label=" Include JWT ID"
              id="includeJwtId"
              checked={value || false}
              inputRef={ref}
              onChange={onChange}
              wrapperClassName="mr-2"
            />
          )}
        />
      </FormControl>

      <FormControl description="If set, the client claims will be sent for every flow. if not, only for client credentials flow (default is false)">
        <Controller
          control={control}
          name="alwaysSendClientClaims"
          render={({ field: { onChange, value, ref } }: any) => (
            <Checkbox
              label="Always Send Application Claims"
              id="alwaysSendClientClaims"
              checked={value || false}
              inputRef={ref}
              onChange={onChange}
              wrapperClassName="mr-2"
            />
          )}
        />
      </FormControl>

      <FormControl description="When checked, user claims like profile, email, address, and phone scope values are included in ID Token. This is mostly the case when used 'id_token' 'response_type' because no Access Token is issued and the resulting claims are included in ID Token.">
        <Controller
          control={control}
          name="alwaysIncludeUserClaimsInIdToken"
          render={({ field: { onChange, value, ref } }: any) => (
            <Checkbox
              label="Always Include User Claims ID Token"
              id="alwaysIncludeUserClaimsInIdToken"
              checked={value || false}
              inputRef={ref}
              onChange={onChange}
              wrapperClassName="mr-2"
            />
          )}
        />
      </FormControl>

      <FormControl description="If set, the prefix client claim types will be prefixed with. Defaults to client_. The intent is to make sure they don’t accidentally collide with user claims.">
        <Label text="Client Claims Prefix" htmlFor="client-claims" />
        <Input
          id="client-claims"
          {...register('clientClaimsPrefix')}
          inputSize={InputSize.sm}
          className={inputClasses}
        />
      </FormControl>

      <FormControl description="Salt value used in pair-wise subjectId generation for users of this client.">
        <Label text="Pair Wise Subject Salt" htmlFor="pair-wise" />
        <Input
          id="pair-wise"
          {...register('pairWiseSubjectSalt')}
          inputSize={InputSize.sm}
          className={inputClasses}
        />
      </FormControl>

      <FormControl
        description="Allows settings claims for the client (will be included in the access token)."
        lastChild
      >
        <Label text="Claims" />
        <NavLink
          to={`${APPS_PATH}/client-claims/${props.client?.id}`}
          state={{
            id: props.client?.id,
            name: props.client?.clientName
          }}
        >
          <Button
            type="button"
            btnStyle={BtnStyle.primary}
            btnSize={BtnSize.normal}
            className={primaryBtnClasses}
          >
            Manage Client Claims
          </Button>
        </NavLink>
      </FormControl>
    </>
  )
}
