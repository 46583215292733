import { useEffect } from "react";
import { Properties } from "../../components";
import { API_RES_PATH } from "../../constants";
import { SEO } from "../../utils";

export const ApiResourceProperties = () => {
  useEffect(() => {
    SEO({
      title: "Porta - API Resource Properties",
    });
  }, []);

  return (
    <Properties
      endpoint="/ApiResources"
      mainTitle="API Resource Properties"
      backTo={API_RES_PATH}
    />
  );
};
