import { useEffect } from "react";
import { Properties } from "../../components";
import { API_SCOPES_PATH } from "../../constants";
import { SEO } from "../../utils";

export const ApiScopeProperties = () => {
  useEffect(() => {
    SEO({
      title: "Porta - API Scope Properties",
    });
  }, []);

  return (
    <Properties
      endpoint="/ApiScopes"
      mainTitle="API Scope Properties"
      backTo={API_SCOPES_PATH}
    />
  );
};
