import { Dispatch, FC, SetStateAction, useState } from 'react'

//@ts-ignore
import { GjIconReact as Icon, showToast } from '@nodus/utilities-front'

import { format } from 'date-fns'

import { FormControl, Input, InputSize, Label, Modal, Table, Td } from '../..'
import { ModalSize } from '../../../components/modal'
import { useFetch, useToggle } from '../../../hooks'
import { ISecret } from '../../../interfaces'

export interface ISecretsTable {
  response: any
  setResponse: any
  handlePageChange?: any
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  perPage: number
  endpoint: string
  loading?: boolean
}

export interface ISecretEl {
  type: string
  expiration: string
  created: string
}

export const SecretsTable: FC<ISecretsTable> = ({
  response,
  setResponse,
  currentPage,
  handlePageChange,
  loading,
  perPage,
  endpoint
}) => {
  const [currentSecret, setCurrentSecret] = useState<any>()
  const { secrets, totalCount } = !!response && response
  const { visible, toggle } = useToggle()
  const { apiCall: deleteSecret, loading: deleteSecretLoading } =
    useFetch('delete')

  const onDeleteSecret = (secret: ISecret) => {
    deleteSecret(`${endpoint}/secrets/${secret.id}`, {}, () => {
      showToast('success', 'Secret deleted successfully!')
      toggle()
      setResponse({
        ...response,
        secrets: secrets?.filter((item: ISecret) => {
          return item.id !== secret.id
        }),
        totalCount: response.totalCount - 1
      })
    })
  }

  const openDeleteSecretModal = (el: ISecretEl) => {
    toggle()
    setCurrentSecret(el)
  }

  return (
    <>
      <Table
        loading={loading || false}
        names={[
          { children: 'Type' },
          // { children: "Value" },
          { children: 'Expiration' },
          { children: 'Created' },
          { children: '', className: 'border-transparent' }
        ]}
        values={response?.secrets}
        renderTr={(el: ISecretEl) => {
          return (
            <tr className="hover:bg-gray-50 transition">
              <Td>{el.type}</Td>
              {/* <Td className="relative">
                <SecretValueDropdown el={el} />
              </Td> */}
              <Td>
                {!!el.expiration
                  ? format(new Date(el.expiration), 'MMMM dd, yyyy')
                  : ''}
              </Td>
              <Td>{format(new Date(el?.created), 'MMMM dd, yyyy')}</Td>
              <Td className="text-primary-secText" align="right">
                <button
                  type="button"
                  onClick={() => openDeleteSecretModal(el)}
                  className="text-primary-secText hover:text-warning"
                >
                  <span className="inline-block w-5 h-5">
                    <Icon name="Delete" size={22} />
                  </span>
                </button>
              </Td>
            </tr>
          )
        }}
        pagination={{
          pageSize: perPage,
          totalCount,
          onPageChange: handlePageChange,
          page: currentPage,
          length: secrets?.length
        }}
        emptyView={{ text: 'There are no secrets yet.' }}
      />

      {visible && (
        <Modal
          visible={visible}
          hide={toggle}
          modalSize={ModalSize.md}
          title="Delete Secret"
          onConfirmClick={() => onDeleteSecret(currentSecret)}
          confirmBtnText="Delete"
          withFooter
          warningModal
          loading={deleteSecretLoading}
          blockOutsideClick
        >
          <p className="mb-6 text-sm">
            Are you sure u want to delete {currentSecret?.type}?
          </p>

          <FormControl>
            <Label text="Secret Type" htmlFor="secret-type" />
            <Input
              id="secret-type"
              inputSize={InputSize.sm}
              readOnly
              value={currentSecret?.type}
              className="bg-primary-stroke"
            />
          </FormControl>

          <FormControl>
            <Label text="Secret value" htmlFor="secret-value" />
            <Input
              inputSize={InputSize.sm}
              readOnly
              value={currentSecret?.value}
              className="bg-primary-stroke"
            />
          </FormControl>
        </Modal>
      )}
    </>
  )
}
