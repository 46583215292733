export const BASE_PATH = '/porta/gjirafa/clients'
export const APPS_PATH = `${BASE_PATH}/applications`
export const API_RES_PATH = `${BASE_PATH}/api-resources`
export const API_SCOPES_PATH = `${BASE_PATH}/api-scopes`
export const I_RES_PATH = `${BASE_PATH}/identity-resources`
export const MANAGE_USER_DATA_DELETION_PATH = `${APPS_PATH}/manage-user-data-deletion`

export const ATTACK_PROTECTION_PATH = '/attack-protection'
export const MULTI_FACTOR_AUTH_PATH = '/multi-factor-auth'
export const BOT_DETECTION_PATH = '/bot-detection'
export const IP_THROTTLING_PATH = '/suspicious-ip-throttling'
export const BRUTE_FORCE_PATH = '/brute-force-protection'
export const BREACHED_PASS_PATH = '/breached-password-detection'

export const I_RESOLUTION_PATH = '/identity-resolution'
