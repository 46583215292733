import { FC } from 'react'
import DatePicker from 'react-date-picker'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import { v4 as uuidv4 } from 'uuid'
//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'

import {
  BtnSize,
  BtnType,
  Button,
  FormControl,
  Input,
  InputSize,
  Label
} from '../../../components'
import TipInfo from '../../../components/ui/TipInfo'
import { IReactHookForm } from '../../../interfaces'
import {
  cx,
  hashTypes,
  inputClasses,
  primaryBtnClasses,
  reactSelectStyle,
  secretTypes
} from '../../../utils'

export interface INewSecretForm extends IReactHookForm {
  onSubmit: () => void
  className?: string
  loading?: boolean
}

export const NewSecretForm: FC<INewSecretForm> = ({
  onSubmit,
  control,
  register,
  errors,
  setValue,
  className,
  loading
}) => {
  const generateSecret = () => {
    setValue('value', uuidv4())
  }

  return (
    <>
      <form onSubmit={onSubmit} className={className && className}>
        <FormControl>
          <Label text="Secret Type" />
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                onChange={onChange}
                options={secretTypes}
                value={value || secretTypes[0]}
                isSearchable
                ref={ref}
                menuPortalTarget={document.body}
                className="flex-1 text-sm mb-2 sm:mt-0 mt-4"
                classNamePrefix="porta-react-select"
                styles={reactSelectStyle}
              />
            )}
          />
        </FormControl>

        <FormControl>
          <Label required text="Secret Value" htmlFor="secret-value" />
          <Input
            id="secret-value"
            {...register('value', { required: 'Secret value is required' })}
            error={!!errors.value && errors.value.message}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
          <button
            type="button"
            onClick={generateSecret}
            className="absolute top-9 text-primary-mainText right-3 hover:text-primary focus:outline-none"
          >
            {/* TODO: should place Icon instead */}
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.5798 4.59003C11.7787 4.44091 12.0607 4.4812 12.2098 4.68003L13.7623 6.75003L12.2098 8.82003C12.0607 9.01885 11.7787 9.05914 11.5798 8.91003C11.381 8.76091 11.3407 8.47885 11.4898 8.28003L12.2998 7.20005H9.82479C8.83286 7.20005 8.02833 8.0024 8.0248 8.99349L8.0248 9.00005C8.0248 9.00223 8.0248 9.00441 8.0248 9.0066C8.02833 9.9977 8.83286 10.8 9.82479 10.8H14.0998L13.2898 9.72003C13.1407 9.5212 13.1809 9.23914 13.3798 9.09003C13.5786 8.94091 13.8607 8.9812 14.0098 9.18003L15.5623 11.25L14.0098 13.32C13.8607 13.5189 13.5786 13.5591 13.3798 13.41C13.1809 13.2609 13.1407 12.9788 13.2898 12.78L14.0998 11.7H9.82479C8.88566 11.7 8.05851 11.2206 7.5748 10.4931C7.09108 11.2206 6.26394 11.7 5.32481 11.7H2.6248C2.37628 11.7 2.1748 11.4986 2.1748 11.25C2.1748 11.0015 2.37628 10.8 2.6248 10.8H5.32481C6.31673 10.8 7.12126 9.9977 7.12479 9.0066C7.12479 9.00441 7.12479 9.00223 7.12479 9.00005L7.12479 8.99349C7.12126 8.00239 6.31673 7.20005 5.32481 7.20005H2.6248C2.37628 7.20005 2.1748 6.99857 2.1748 6.75005C2.1748 6.50152 2.37628 6.30004 2.6248 6.30004H5.32481C6.26394 6.30004 7.09108 6.77952 7.5748 7.50702C8.05851 6.77952 8.88566 6.30004 9.82479 6.30004H12.2999L11.4898 5.22003C11.3407 5.0212 11.381 4.73914 11.5798 4.59003Z" />
            </svg>
          </button>
        </FormControl>

        <FormControl>
          <Label htmlFor="hashType" text="Hash Type" />

          <Controller
            control={control}
            name="hashType"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                onChange={onChange}
                options={hashTypes}
                value={value || hashTypes[0]}
                isSearchable
                ref={ref}
                menuPortalTarget={document.body}
                className={cx([
                  'flex-1 text-sm mb-2 sm:mt-0 mt-4',
                  errors.clientType && 'invalid-field'
                ])}
                classNamePrefix="porta-react-select"
                styles={reactSelectStyle}
              />
            )}
          />
        </FormControl>

        <FormControl>
          <TipInfo message="HashType will be applicable only for the SharedSecret type." />
        </FormControl>

        <FormControl>
          <Label htmlFor="expiration" text="Expiration" />
          <Controller
            control={control}
            name="expiration"
            render={({ field: { onChange, value } }: any) => {
              return (
                <DatePicker
                  calendarIcon={
                    <span className="inline-block w-5 h-5">
                      <Icon name="Calendar" size={18} />
                    </span>
                  }
                  className="w-full bg-white focus:shadow-primary"
                  onChange={onChange}
                  value={value || ''}
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                />
              )
            }}
          />
        </FormControl>

        <Button
          type="submit"
          btnSize={BtnSize.normal}
          btnType={BtnType.primary}
          disabled={loading}
          className={cx(['flex items-center', primaryBtnClasses])}
        >
          {loading && (
            <img
              src={require('@/assets/image/oval.svg')}
              alt="Spinner"
              width={16}
              className="mr-2"
            />
          )}
          Add Secret
        </Button>
      </form>
    </>
  )
}
