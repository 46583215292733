import { FC, useEffect, useState } from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation } from 'react-router'
import { useParams } from 'react-router-dom'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'

import {
  BackButton,
  BtnSize,
  BtnType,
  Button,
  Container,
  FormControl,
  Input,
  InputSize,
  Label,
  SectionTitle
} from '../../components'
import { MultiTagsCard } from '../../components/ui/MultiTagsCard'
import { APPS_PATH } from '../../constants'
import { useCurrentPage, useFetch } from '../../hooks'
import { IClaim } from '../../interfaces'
import { cx, inputClasses, primaryBtnClasses } from '../../utils'
import { ClaimsTable } from './components'

export const ApplicationClaims: FC = () => {
  let { id: paramId } = useParams()
  const { state } = useLocation() as any
  const [selectedClaim, setSelectedClaim] = useState<string[]>([])

  const { currentPage, handlePageChange } = useCurrentPage()
  const [response, setResponse] = useState<any>()
  const PER_PAGE = 10

  const { apiCall: getClientClaims, loading } = useFetch('get')
  const { apiCall: addAClientClaim, loading: addClientClaimLoading } =
    useFetch('post')
  const { clientClaims, totalCount } = !!response && response

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    if (!!paramId)
      getClientClaims(
        `/Clients/${paramId}/Claims?page=${currentPage}&pageSize=${PER_PAGE}`,
        {},
        (response) => {
          setResponse(response)
        }
      )
  }, [paramId, currentPage]) // eslint-disable-line react-hooks/exhaustive-deps

  const addNewClaim: SubmitHandler<any> = (data: IClaim) => {
    const claimObj = {
      ...data,
      type: selectedClaim[0]
    }

    addAClientClaim(
      `/Clients/${paramId}/Claims`,
      claimObj,
      (response) => {
        setResponse({
          ...response,
          totalCount: totalCount + 1,
          clientClaims: [...clientClaims, response]
        })
        showToast('success', 'Claim added successfuly!')

        reset()
        setSelectedClaim([])
      },
      (err) => {
        const errResponse = err.response.data
        if (errResponse?.errors && errResponse?.errors['Type'])
          showToast('error', errResponse?.errors['Type'][0])
      }
    )
  }

  return (
    <>
      <Container>
        <BackButton
          to={`${APPS_PATH}/${paramId}`}
          label={`Back to ${state?.name}`}
        >
          <></>
        </BackButton>

        <SectionTitle>Client Claims</SectionTitle>

        <form className="mb-6">
          <FormControl>
            <Label required text="Claim Type" />
            <MultiTagsCard
              tags={selectedClaim}
              setTags={setSelectedClaim}
              hasInput
              suggestedEndpoint="/Clients/SearchClaims?limit=0"
              hasOneTag
            />

            {/* {hasError && !type && (
              <Message
                type={MessageTypes.error}
                containerClassName="mt-2"
                message="Client claim type is required"
              />
            )} */}
          </FormControl>

          <FormControl>
            <Label required text="Claim Value" />
            <Input
              {...register('value', {
                required: 'Client claim value is required'
              })}
              error={!!errors.value && errors.value.message}
              inputSize={InputSize.sm}
              className={inputClasses}
            />
          </FormControl>

          <Button
            onClick={handleSubmit(addNewClaim)}
            type="button"
            btnSize={BtnSize.normal}
            btnType={BtnType.primary}
            disabled={addClientClaimLoading}
            className={cx(['flex items-center', primaryBtnClasses])}
          >
            {addClientClaimLoading && (
              <img
                src={require('@/assets/image/oval.svg')}
                alt="Spinner"
                width={16}
                className="mr-2"
              />
            )}
            Add Client Claim
          </Button>
        </form>

        <ClaimsTable
          tableProps={{
            loading,
            totalCount,
            handlePageChange,
            currentPage,
            setResponse,
            response
          }}
        />
      </Container>
    </>
  )
}
