import { FC } from 'react'

import {
  BtnSize,
  BtnType,
  Button,
  FormControl,
  Input,
  InputSize,
  Label
} from '../../../components'
import { IReactHookForm } from '../../../interfaces'
import { cx, inputClasses, primaryBtnClasses } from '../../../utils'

export interface INewProperyForm extends IReactHookForm {
  onSubmit: () => void
  className?: string
  loading?: boolean
}

export const NewProperyForm: FC<INewProperyForm> = ({
  onSubmit,
  register,
  errors,
  className,
  loading
}) => {
  return (
    <>
      <form className={className && className}>
        <FormControl>
          <Label required text="Key" />
          <Input
            {...register('key', { required: 'Property key is required' })}
            error={!!errors.key && errors.key.message}
            inputSize={InputSize.sm}
            className={inputClasses}
            autoFocus
          />
        </FormControl>

        <FormControl>
          <Label required text="Value" />
          <Input
            {...register('value', { required: 'Property value is required' })}
            error={!!errors.value && errors.value.message}
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </FormControl>

        <Button
          onClick={onSubmit}
          type="button"
          btnSize={BtnSize.normal}
          btnType={BtnType.primary}
          disabled={loading}
          className={cx(['flex items-center', primaryBtnClasses])}
        >
          {loading && (
            <img
              src={require('@/assets/image/oval.svg')}
              alt="Spinner"
              width={16}
              className="mr-2"
            />
          )}
          Add Property
        </Button>
      </form>
    </>
  )
}
