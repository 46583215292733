import { ChangeEvent, useEffect, useState } from 'react'
//@ts-ignore
import { GjIconReact as Icon, showToast } from '@nodus/utilities-front'

import { FormControl, Label } from '../../../components'
import { useApplicationContext } from '../../../context/ApplicationContext'
import { useFetch } from '../../../hooks'
import { IReactHookForm } from '../../../interfaces'
import { cancelBtnClasses, cx, validateImage } from '../../../utils'

interface IApplicationLogo extends IReactHookForm {}

export const ApplicationLogo: React.FC<IApplicationLogo> = ({
  register,
  setValue
}) => {
  const [image, setImage] = useState<string>('')
  const { appData } = useApplicationContext()
  const { logoUri, id, clientName } = !!appData && appData
  const { apiCall: addClientLogo, loading } = useFetch('post')

  useEffect(() => {
    register('logoUri')
  }, [register])

  useEffect(() => {
    setImage(logoUri)
  }, [logoUri])

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files) {
      const file = e.target?.files[0]

      e.target.value = ''
      const validateImageMessage = validateImage(
        file,
        'image/x-png, image/png, image/jpg, image/jpeg, image/svg+xml' || '',
        2097152,
        '2MB'
      )

      if (validateImageMessage?.length) {
        showToast('error', validateImageMessage)
      } else {
        const formData = new FormData()
        formData.append('file', file)

        addClientLogo(
          `/Clients/addClientLogo?id=${id}`,
          formData,
          (res) => {
            setImage(res)
            setValue('logoUri', res)
          },
          (err) => {
            console.log(err?.response)
          }
        )
      }
    }
  }

  const removeAppLogo = async () => {
    setValue('logoUri', null)
    setImage('')
  }

  const InputFile = ({
    withIcon,
    hasImage
  }: {
    withIcon: boolean
    hasImage: boolean
  }) => {
    return (
      <label
        htmlFor="picture-file"
        className={cx([
          'cursor-pointer inline-flex transition items-center justify-center w-full h-full group absolute left-0 top-0 z-10',
          hasImage && 'hover:bg-primary-mainText hover:bg-opacity-40 rounded'
        ])}
      >
        <input
          type="file"
          onChange={handleFileChange}
          disabled={loading}
          id="picture-file"
          accept="image/*"
          className="hidden"
        />
        {withIcon && (
          <span
            className={cx([
              'transform group-hover:-translate-y-1 inline-block w-6 h-6 transition group-hover:border-primary-mainText',
              hasImage
                ? 'text-white opacity-0 group-hover:opacity-100'
                : 'text-primary-secText opacity-100 group-hover:text-primary-mainText '
            ])}
          >
            <Icon name="Upload" size={25} />
          </span>
        )}
      </label>
    )
  }

  return (
    <FormControl description="An application logo is a unique image used to represent an application on Porta. This logo will only appear in the applications section.">
      <Label text="Application logo" />
      <div className="flex items-center">
        <div
          className={cx([
            'rounded w-20 border-primary-stroke h-20 relative flex items-center justify-center',
            image ? 'border' : 'border-primary-stroke border-dashed border-2'
          ])}
        >
          {loading && (
            <img
              src={require('@/assets/image/oval.svg')}
              alt="Spinner"
              width={16}
              className="mr-2"
            />
          )}

          {!loading && (
            <>
              {image ? (
                <InputFile withIcon hasImage={!!image} />
              ) : (
                <span className="text-primary-secText inline-block w-6 h-6">
                  <Icon name="Image" size={25} />
                </span>
              )}
            </>
          )}

          <img
            src={image}
            alt={clientName}
            className={cx([
              'absolute left-0 top-0 w-full h-full object-cover rounded transition transform',
              image ? 'opacity-100' : 'opacity-0'
            ])}
          />
        </div>

        <div className="ml-8">
          {image ? (
            <button
              type="button"
              onClick={removeAppLogo}
              disabled={loading}
              className={cx([
                cancelBtnClasses,
                loading && 'pointer-events-none opacity-60',
                'bg-gray-100 hover:bg-gray-200 text-white rounded flex items-center relative text-sm'
              ])}
            >
              <span className="mr-2 inline-block w-5 h-5">
                <Icon name="Close" size={21} />
              </span>{' '}
              Remove logo
            </button>
          ) : (
            <button
              type="button"
              disabled={loading}
              className={cx([
                loading && 'pointer-events-none opacity-60',
                cancelBtnClasses,
                'bg-gray-100 hover:bg-gray-200 text-white rounded flex items-center relative text-sm'
              ])}
            >
              <span className="mr-2 inline-block w-5 h-5">
                <Icon name="Upload" size={20} />
              </span>{' '}
              Upload logo
              <InputFile withIcon={false} hasImage={false} />
            </button>
          )}
        </div>
      </div>
    </FormControl>
  )
}
