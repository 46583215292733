import { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router'
import { SubmitHandler, useForm } from 'react-hook-form'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'

import { IApiScope } from '../../interfaces'
import { Loader, Modal } from '../../components'
import { API_SCOPES_PATH } from '../../constants'
import { useFetch, useToggle } from '../../hooks'
import { ApiScopeForm } from './components'
import { SEO } from '../../utils'

export const ApiScope = () => {
  const { toggle, visible } = useToggle()
  const navigate = useNavigate()
  let { id } = useParams()

  const [claims, setClaims] = useState<string[]>([])

  const { apiCall: getApiScope, loading, response: apiScope } = useFetch('get')
  const { apiCall: editApiScope, loading: editApiScopeLoading } =
    useFetch('put')
  const { apiCall: deleteApiScope, loading: deleteApiScopeLoading } =
    useFetch('delete')

  const { name, userClaims } = !!apiScope && apiScope

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    SEO({
      title: 'Porta - API Scope'
    })
  }, [])

  useEffect(() => {
    if (!!id)
      getApiScope(`/ApiScopes/${id}`, {}, (response) => {
        reset(response)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setClaims(userClaims)
  }, [userClaims])

  const onEditApiScope: SubmitHandler<any> = (data: IApiScope) => {
    const apiResourceData = {
      ...data,
      name: data.name.trim(),
      userClaims: claims
    }

    editApiScope(
      `/ApiScopes`,
      apiResourceData,
      () => {
        showToast('success', 'API scope saved successfully!')
      },
      (error) => {
        const errRes = error?.response?.data

        if (errRes?.errors?.Name) {
          showToast('error', errRes?.errors?.Name[0])
        }

        if (errRes?.errors[`Api Scope already exists!`]) {
          showToast('error', errRes?.errors[`Api Scope already exists!`][0])
        }
      }
    )
  }

  const onDeleteApiScope = () => {
    deleteApiScope(`/ApiScopes/${id}`, {}, () => {
      showToast('success', 'API Scope deleted successfully!')
      navigate(API_SCOPES_PATH)
      toggle()
    })
  }

  if (loading) {
    return <Loader />
  }

  const props = {
    onSubmit: handleSubmit(onEditApiScope),
    claims,
    setClaims,
    apiScope,
    id,
    toggle,
    register,
    control,
    errors,
    editMode: true,
    loading: editApiScopeLoading
  }

  return (
    <>
      <ApiScopeForm {...props} />

      {visible && (
        <Modal
          visible={visible}
          hide={toggle}
          title="Delete API Scope"
          onConfirmClick={onDeleteApiScope}
          confirmBtnText="Delete"
          withFooter
          warningModal
          blockOutsideClick
          loading={deleteApiScopeLoading}
        >
          <p className="text-sm text-gray-700">
            Are you sure you want to delete <strong>{name}</strong>?
          </p>
        </Modal>
      )}
    </>
  )
}
