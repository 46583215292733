import { useEffect, useState } from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'
import Select from 'react-select'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'

import {
  BackButton,
  FormControl,
  FormWrapper,
  Input,
  InputSize,
  Label,
  Loader
} from '../../components'
import { HeadlineStatus } from '../../components/headline'
import { APPS_PATH } from '../../constants'
import { useFetch } from '../../hooks'
import { SEO, inputClasses, reactSelectStyle } from '../../utils'
import { IDeleteAccoutOptions } from './interface'

export const ManageUserDeletion = () => {
  const {
    apiCall: getDeleteAcountOptions,
    response: deleteAccountOptions,
    loading: getDeleteOptionsLoading
  } = useFetch('get')
  const {
    apiCall: updateDeleteAcountOptions,
    loading: updateDeleteAccountOptionsLoading
  } = useFetch('post')

  let { id: paramId } = useParams()
  const { state } = useLocation() as any

  const DELETE_ACCOUNT_API_URL = `/Clients/DeleteAccountOptions/${paramId}`

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset
  } = useForm()

  const [activeOption, setActiveOption] = useState({
    name: 'Email',
    id: 'email'
  })

  const dropdownOptions = [
    { name: 'Email', id: 'email' },
    { name: 'Endpoint URL', id: 'endpoint' }
  ]

  useEffect(() => {
    SEO({
      title: 'Porta - Manage User Deletion'
    })
  }, [])

  const handleSelectChange = (selected: any) => {
    setActiveOption(selected)
  }

  const onFormSubmit: SubmitHandler<any> = (data: IDeleteAccoutOptions) => {
    const dataObj = {
      ...data,
      apiKeyEnabled: activeOption.id === 'endpoint' && true,
      emailEnabled: activeOption.id === 'email' && true
    }

    updateDeleteAcountOptions(DELETE_ACCOUNT_API_URL, dataObj, () => {
      showToast('success', 'Account deletetion data saved successfully!')
    })
  }

  useEffect(() => {
    getDeleteAcountOptions(DELETE_ACCOUNT_API_URL, {}, (res) => {
      reset(res)
      setActiveOption(dropdownOptions[res?.apiKeyEnabled ? 1 : 0])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setActiveOption({
      ...(deleteAccountOptions?.emailEnabled
        ? {
            name: 'Email',
            id: 'email'
          }
        : {
            name: 'Endpoint URL',
            id: 'endpoint'
          })
    })
  }, [deleteAccountOptions])

  const deleteAccountOptionsActive = watch('deleteAccountOptionsEnabled')

  if (getDeleteOptionsLoading) {
    return <Loader />
  }

  return (
    <FormWrapper
      onSubmit={handleSubmit(onFormSubmit)}
      loading={updateDeleteAccountOptionsLoading}
    >
      <BackButton
        to={`${APPS_PATH}/${paramId}`}
        label={`Back to ${state?.name}`}
      >
        <></>
      </BackButton>

      <HeadlineStatus
        control={control}
        title="Manage User Data Deletion"
        description="Manage how you are informed of a user's request to delete their data so that you can comply with user's request across your application(s)."
        statusEnabled={deleteAccountOptionsActive}
        toggleId="user-data-deletion"
        toggleName="deleteAccountOptionsEnabled"
        className="mb-8"
      />
      <FormControl>
        <Label text="Select where the application will be informed*" />
        <Select
          onChange={handleSelectChange}
          defaultValue={activeOption}
          value={activeOption}
          options={dropdownOptions}
          getOptionLabel={(x) => x.name}
          getOptionValue={(x) => x.id}
          isSearchable
          menuPortalTarget={document.body}
          className="w-full text-sm"
          classNamePrefix="porta-react-select"
          styles={reactSelectStyle}
        />
      </FormControl>

      {activeOption.id === 'email' ? (
        <FormControl>
          <Label required text="Email" />
          <Input
            autoFocus
            {...register('email', {
              required: 'Email is required'
            })}
            error={!!errors.email && errors.email.message}
            inputSize={InputSize.sm}
            className={inputClasses}
            disabled={!deleteAccountOptionsActive}
            maxLength={320}
            placeholder="Enter a valid email address here"
          />
        </FormControl>
      ) : (
        <>
          <FormControl>
            <Label required text="API Key" />
            <Input
              autoFocus
              {...register('apiKey', {
                required: 'API Key is required'
              })}
              error={!!errors.apiKey && errors.apiKey.message}
              inputSize={InputSize.sm}
              className={inputClasses}
              disabled={!deleteAccountOptionsActive}
              maxLength={200}
              placeholder="Enter the API Key here"
            />
          </FormControl>

          <FormControl>
            <Label required text="Endpoint URL" />
            <Input
              {...register('apiEndpoint', {
                required: 'Endpoint URL is required'
              })}
              error={!!errors.apiEndpoint && errors.apiEndpoint.message}
              inputSize={InputSize.sm}
              disabled={!deleteAccountOptionsActive}
              className={inputClasses}
              maxLength={500}
              placeholder="Enter a valid Endpoint URL here"
            />
          </FormControl>
        </>
      )}
    </FormWrapper>
  )
}
