import { ReactNode } from "react";
import { cx } from "../../../utils";

interface ITimeControllFrame {
  className?: string;
  children: ReactNode;
  text: string;
}

export const TimeControllFrame: React.FC<ITimeControllFrame> = ({
  className,
  children,
  text,
}) => {
  return (
    <div className={cx(["flex", className])}>
      <div className="flex-1">{children}</div>

      <p className="text-sm text-primary-secText ml-3 relative top-2.5">
        {text}
      </p>
    </div>
  );
};
