import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'

//@ts-ignore
import { GjIconReact as Icon, showToast } from '@nodus/utilities-front'

import { useLoadMore } from '../../hooks'
import { ITag } from '../../interfaces'
import { axios, cx, inputClasses } from '../../utils'
import { Input, InputSize } from '../input'
import { Card, CardSpace, CardStyle } from '../layout/Card'

interface IMultiTagsCard extends ITag {
  className?: string
  hasInput?: boolean
  hasOneTag?: boolean
  suggestedEndpoint?: string
  noSuggestedTags?: boolean
}

export const MultiTagsCard: React.FC<IMultiTagsCard> = ({
  tags,
  setTags,
  hasInput,
  hasOneTag,
  suggestedEndpoint,
  noSuggestedTags,
}) => {
  const { onLoadMore, itemNum } = useLoadMore(5, 5)
  const [inputValue, setInputValue] = useState<string>('')
  const [suggestedTags, setSuggestedTags] = useState<string[]>([])

  const getSuggestedTags = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api${suggestedEndpoint}` || ''
      )

      const filteredSuggestions = res?.data?.filter(
        (name: string) => !tags?.includes(name)
      )

      setSuggestedTags(filteredSuggestions)
    } catch (err: any) {
      showToast('error', err.message)
    }
  }

  useEffect(() => {
    if (!noSuggestedTags) getSuggestedTags()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTagChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const itemExists = (items: string[], comparisonValue: string) => {
    return !!items && items?.some((tag) => tag === comparisonValue)
  }

  const handleTagKeypress = (event: KeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    const tagExists = itemExists(tags, value)

    if (event.key === 'Enter' && tagExists) {
      showToast('error', `${value} item already selected`)
    }

    if (event.key === 'Enter' && !tagExists && value !== '') {
      event.preventDefault()
      if (hasOneTag) {
        setTags([value])
      } else {
        setTags([...tags, value])
      }
      setInputValue('')
    }
  }

  const handleTagClick = (tag: string) => {
    if (hasOneTag) {
      setTags([tag])
    } else {
      const tagExists = itemExists(tags, tag)

      if (tagExists) showToast('error', `${tag} item already selected`)
      if (!tagExists) {
        setTags([...tags, tag])
        const items = suggestedTags?.filter((item) => {
          return item !== tag
        })

        setSuggestedTags(items)
      }
    }
  }

  const handleTagRemove = (name: string) => {
    const newTags = tags.filter((tag) => tag !== name)
    const tagExists = itemExists(suggestedTags, name)

    setTags(newTags)
    if (!tagExists) setSuggestedTags([...suggestedTags, name])
  }

  const dividedSectionClass =
    'text-xs text-primary-secText border-b border-primary-stroke pb-2 mb-4'
  const emptySectionClass = 'xs text-primary-tertText'

  return (
    <Card
      cardSpace={CardSpace.md}
      cardStyle={CardStyle.bordered}
      className="pb-0"
    >
      {hasInput && (
        <div className="mb-5">
          <Input
            onChange={handleTagChange}
            onKeyPress={handleTagKeypress}
            value={inputValue}
            placeholder="Enter two or more characters"
            inputSize={InputSize.sm}
            className={inputClasses}
          />
        </div>
      )}

      {!noSuggestedTags && (
        <div className={cx([dividedSectionClass, 'mt-3'])}>
          Selected {hasOneTag ? 'item' : 'items'}:
        </div>
      )}

      <div>
        {/* TODO: should be a component */}
        {tags?.map((tag) => {
          return (
            <div
              key={tag}
              className="py-2 px-3 border border-primary rounded-full inline-flex items-center mr-3 mb-3"
            >
              <span className="text-sm text-primary-mainText leading-4">
                {tag}
              </span>

              <button
                type="button"
                className="hover:text-primary cursor-pointer hover:bg-gray-100 focus:outline-none rounded-full ml-3 focus:shadow-outlineGray"
                title="Remove"
                onClick={() => handleTagRemove(tag)}
              >
                <span className="inline-block w-5 h-5">
                  <Icon name="Close" size={20} />
                </span>
              </button>
            </div>
          )
        })}

        {tags?.length < 1 && (
          <p className={`${emptySectionClass} mb-6`}>No items selected</p>
        )}
      </div>

      {!noSuggestedTags && (
        <>
          <div className={cx(['mt-4', dividedSectionClass])}>
            Suggested items:
          </div>

          {/* TODO: should be a component */}
          {suggestedTags?.slice(0, itemNum).map((item) => (
            <button
              type="button"
              key={item}
              onClick={() => handleTagClick(item)}
              className="py-2 px-3 bg-gray-100 hover:bg-gray-200 text-primary-mainText rounded-full inline-flex items-center mr-3 mb-3 cursor-pointer transition focus:shadow-outlineGray focus:bg-gray-100 focus:outline-none"
            >
              <span className="text-sm leading-4 mr-2">{item}</span>

              <span className="inline-block w-5 h-5">
                <Icon name="Plus" size={20} />
              </span>
            </button>
          ))}

          {suggestedTags?.length < 1 && suggestedTags?.length < 1 && (
            <p className={cx([emptySectionClass, 'mb-4'])}>
              No items suggested
            </p>
          )}

          {itemNum < suggestedTags?.length && suggestedTags?.length > 0 && (
            <button
              className="text-xs text-primary hover:underline focus:outline-none mb-3"
              onClick={onLoadMore}
              type="button"
            >
              View more
            </button>
          )}
        </>
      )}
    </Card>
  )
}
