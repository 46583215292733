import { RouteProps } from "react-router-dom";
import {
  API_RES_PATH,
  API_SCOPES_PATH,
  APPS_PATH,
  I_RES_PATH,
  MANAGE_USER_DATA_DELETION_PATH
} from "../constants";
import {
  ApiResource,
  ApiResourceCreate,
  ApiResourceProperties,
  ApiResourceSecrets,
  ApiResources,
  ApiScope,
  ApiScopeCreate,
  ApiScopeProperties,
  ApiScopes,
  Application,
  ApplicationClaims,
  ApplicationProperties,
  ApplicationSecrets,
  Applications,
  IdentityRescourceProperties,
  IdentityResource,
  IdentityResourceCreate,
  IdentityResources,
  ManageUserDeletion
} from "../pages";
type BreadcrumbsPropsItem = {
  name?: string;
  to?: string;
};
import { premiumFeatureEnabled } from "../utils";

enum Permission {
  admin = "SuperAdmin",
  usersEditor = "EditorUsers",
  appsEditor = "EditorSpecificApps",
}

const { admin, appsEditor } = Permission;

export interface RoutesProps extends RouteProps {
  name?: string;
  crumbs?: BreadcrumbsPropsItem[];
  permissions?: Permission[];
}

export const applicationsRoutes: RoutesProps[] = [
// Applications
{
  path: APPS_PATH,
  element: <Applications />,
  name: "Applications",
  crumbs: [{ name: "Applications" }],
  permissions: [admin, appsEditor],
},

{
  path: `${APPS_PATH}/:id/*`,
  element: <Application />,
  name: "Applications",
  crumbs: [
    { name: "Applications", to: `${APPS_PATH}` },
    { name: "Application" },
  ],
  permissions: [admin, appsEditor],
},
{
  path: `${APPS_PATH}/client-secrets/:id`,
  element: <ApplicationSecrets />,
  name: "Applications",
  crumbs: [
    { name: "Applications", to: `${APPS_PATH}` },
    { name: "Application", to: `${APPS_PATH}/:id` },
    { name: "Secret" },
  ],
  permissions: [admin, appsEditor],
},
{
  path: `${APPS_PATH}/client-properties/:id`,
  element: <ApplicationProperties />,
  name: "Applications",
  crumbs: [
    { name: "Applications", to: `${APPS_PATH}` },
    { name: "Application", to: `${APPS_PATH}/:id` },
    { name: "Client Properties" },
  ],
  permissions: [admin, appsEditor],
},
{
  path: `${APPS_PATH}/client-claims/:id`,
  element: <ApplicationClaims />,
  name: "Applications",
  crumbs: [
    { name: "Applications", to: `${APPS_PATH}` },
    { name: "Application" },
    { name: "Client Claims" },
  ],
  permissions: [admin, appsEditor],
},
{
  ...(premiumFeatureEnabled["userDeletion"] === true && {
    path: `${MANAGE_USER_DATA_DELETION_PATH}/:id`,
    element: <ManageUserDeletion />,
    name: "Applications",
    crumbs: [
      { name: "Applications", to: `${APPS_PATH}` },
      { name: "Application", to: `${APPS_PATH}/:id` },
      { name: "Manage User Deletion" },
    ],
    permissions: [admin],
  }),
},
// API Resources
{
  path: API_RES_PATH,
  element: <ApiResources />,
  name: "API Resources",
  crumbs: [{ name: "API Resources" }],
  permissions: [admin, appsEditor],
},
{
  path: `${API_RES_PATH}/create`,
  element: <ApiResourceCreate />,
  name: "API Resource",
  crumbs: [{ name: "API Resources", to: API_RES_PATH }, { name: "Create" }],
  permissions: [admin, appsEditor],
},
{
  path: `${API_RES_PATH}/:id`,
  element: <ApiResource />,
  name: "API Resources",
  crumbs: [
    { name: "API Resources", to: `${API_RES_PATH}` },
    { name: "API Resource" },
  ],
  permissions: [admin, appsEditor],
},
{
  path: `${API_RES_PATH}/api-secrets/:id`,
  element: <ApiResourceSecrets />,
  name: "API Secrets",
  crumbs: [
    { name: "API Resources", to: `${API_RES_PATH}` },
    { name: "API Secrets" },
  ],
  permissions: [admin, appsEditor],
},
{
  path: `${API_RES_PATH}/api-properties/:id`,
  element: <ApiResourceProperties />,
  name: "API Properties",
  crumbs: [
    { name: "API Resources", to: `${API_RES_PATH}` },
    { name: "API Secrets" },
  ],
  permissions: [admin, appsEditor],
},

// API Scopes
{
  path: API_SCOPES_PATH,
  element: <ApiScopes />,
  name: "API Scopes",
  crumbs: [{ name: "API Scopes" }],
  permissions: [admin, appsEditor],
},
{
  path: `${API_SCOPES_PATH}/create`,
  element: <ApiScopeCreate />,
  name: "API Scope",
  crumbs: [{ name: "API Scopes", to: API_SCOPES_PATH }, { name: "Create" }],
  permissions: [admin, appsEditor],
},
{
  path: `${API_SCOPES_PATH}/:id`,
  element: <ApiScope />,
  name: "API Scope",
  crumbs: [
    { name: "API Scopes", to: `${API_SCOPES_PATH}` },
    { name: "API Scope" },
  ],
  permissions: [admin, appsEditor],
},
{
  path: `${API_SCOPES_PATH}/api-scope-properties/:id`,
  element: <ApiScopeProperties />,
  name: "API Scope Properties",
  crumbs: [
    { name: "API Scopes", to: `${API_SCOPES_PATH}` },
    { name: "API Scope Properties" },
  ],
  permissions: [admin, appsEditor],
},

// Identity Resources
{
  path: I_RES_PATH,
  element: <IdentityResources />,
  name: "Identity Resources",
  crumbs: [{ name: "Identity Resources" }],
  permissions: [admin, appsEditor],
},
{
  path: `${I_RES_PATH}/create`,
  element: <IdentityResourceCreate />,
  name: "Identity Resource",
  crumbs: [
    { name: "Identity Resources", to: I_RES_PATH },
    { name: "Create" },
  ],
  permissions: [admin],
},
{
  path: `${I_RES_PATH}/:id`,
  element: <IdentityResource />,
  name: "Identity Resource",
  crumbs: [
    { name: "Identity Resources", to: `${I_RES_PATH}` },
    { name: "Identity Resource" },
  ],
  permissions: [admin],
},
{
  path: `${I_RES_PATH}/identity-resource-properties/:id`,
  element: <IdentityRescourceProperties />,
  name: "Identity Resource Properties",
  crumbs: [
    { name: "Identity Resource", to: `${I_RES_PATH}` },
    { name: "Identity Resource Properties" },
  ],
  permissions: [admin],
},
];
